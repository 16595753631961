const staticOptions = {
  nocache: true,
  adapter: null
}
Object.preventExtensions(staticOptions)

const METHOD_GET = 'GET'
const METHOD_POST = 'POST'

function nocacheHeaders() {
  return {
    'pragma': 'no-cache',
    'cache-control': 'no-cache'
  }
}

function defaultHeaders() {
  const headers = {
    'X-Requested-With': 'XMLHttpRequest',
    'Ajax-Request': 'XMLHttpRequest'
  }
  if (staticOptions.nocache) Object.assign(headers, nocacheHeaders())
  return headers;
}

function defaultArgs(){
  return {
    url : null,
    method: METHOD_GET,
    headers: defaultHeaders(),
    continuous: false,//function(response){ },
    cancel: function(){ return false; }
  }
}

function parseArgs(args) {
  const data = defaultArgs();
  if (args.length === 0) {
    throw SyntaxError('invalid arguments')

  } else if (args.length > 1) {
    data.url = args[0]
    data.method = METHOD_POST
    if (typeof args[1] === 'object') {
      Object.assign(data, args[1]);
      data.data = typeof data.data === 'object' ? postData2QueryStr(data.data) : data.data || '';
    }

  } else if (typeof args[0] === 'object') {
    Object.assign(data, args[0])
    data.method = args[0].method || (data.data && METHOD_POST) || data.method
    data.data = typeof data.data === 'object' ? postData2QueryStr(data.data) : data.data || null
    //const data = request.data ? typeof request.data === 'object' ? JSON.stringify(request.data) : request.data : null

  } else {
    data.url = args[0]
  }
  if (data.method === METHOD_POST) addPostHeaders(data.headers)
    return data;
}

function postData2QueryStr(obj, prefix) {
  let str = [];
  if (prefix && (obj instanceof Array) && obj.length === 0) {
    return encodeURIComponent(prefix) + '='
  } else for (let p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p) && obj[p] !== undefined) {
      const k = prefix ? prefix + '[' + p + ']' : p
      const v = obj[p]
      str.push((v !== null && typeof v === 'object') ?
        postData2QueryStr(v, k) :
        encodeURIComponent(k) + '=' + (v === null ? '' : encodeURIComponent(v)))
    }
  }
  return str.join("&")
}

/**
 * @param {Object} headers
 */
function addPostHeaders(headers) {
  Object.assign(headers, nocacheHeaders())
  headers['Content-Type'] = 'application/x-www-form-urlencoded'
}

/**
 * @returns {XMLHttpRequest}
 */
function adapter() {
  const adapter = staticOptions.adapter || XMLHttpRequest
  if (!adapter) throw Error('XMLHttpRequest is not supported')
  return new adapter()
}
/**
 *
 * @param {Object} request
 * @see defaultArgs
 */
function send(request) {
  const xhr = adapter()
  if (!request.url) throw TypeError('URL not defined')
  return new Promise(function(resolve, reject) {
    let observer
    const cancel = function(){
      if (request.cancel()) {
        xhr.abort()
        clearInterval(observer);
        reject(new cancelHandler(xhr))
        return true
      } else return false;
    }
    xhr.open(request.method, request.url, true)
    xhr.onreadystatechange = function () {
      if (!cancel() && xhr.readyState === 4) {
        xhr.status === 200 ? resolve(xhr) : reject(xhr)
        clearInterval(observer)
      }
    }
    for (let key in request.headers) xhr.setRequestHeader(key, request.headers[key])
    xhr.send(request.data || null)
    observer = setInterval(cancel, 100)

    /** @param {ProgressEvent} event
    const handleEvent = function(event){console.log('ajax.event', args); }
    xhr.addEventListener('loadstart', handleEvent);
    xhr.addEventListener('load', handleEvent);
    xhr.addEventListener('loadend', handleEvent);
    xhr.addEventListener('progress', handleEvent);
    xhr.addEventListener('error', handleEvent);
    xhr.addEventListener('abort', handleEvent);
    */
  })
}

/**
 *
 * @param {XMLHttpRequest} xhr
 * @param {Object} data
 * @returns {any}
 */
function response(xhr, data) {
  if (typeof data.header_observer === 'function') data.header_observer(getHeaders(xhr))
  if (data.dataType === "json") return JSON.parse(xhr.response)
  else return xhr.response
}

/**
 * @param {Object|String} data
 * @returns {Promise.<any>}
 */
function ajax(data) {
  data = parseArgs(arguments)
  return send(data).then(xhr => response(xhr, data));
}


/**
 * @param {Object} request
 * @param {?Object} result
 * @return {Promise.<object>}
 *
 * ajax.json(url, {
 *   data: {},
 *   continuous: ({progress, statusMessage}) => {
 *       this.progress = progress
 *   }
 * }).then(...)
 */
function continuous (request, result) {
  return send(request)
    .then(xhr => response(xhr, request))
    .then(json => new Promise((resolve, reject) => {
      if (request.continuous) {
        if (!result) result = {}
        request.url = json.response && json.response.location || request.url
        mergeContinuousResponse(result, json.response)
        if (json.code === 100 && !request.cancel()) {
          if (request.continuous(json.response) === false) {
            return resolve(Object.assign(json, {response: result}))
          }
          setTimeout(function(){
            continuous(request, result).then(resolve).catch(reject)
          }, 500)
        } else {
          if (!json.code || isCodeSuccessful(json.code)) request.continuous(json.response)
          resolve(Object.assign(json, {response: result}))
        }
      } else return resolve(Object.assign(json, result ? {response: result} : {}))
    }))
}

/**
 * @param {Object} result
 * @param {Object} response
 */
function mergeContinuousResponse(result, response) {
  if (response && response.list) {
    if (!result.list) result.list = response.list instanceof Array ? [] : {}
    if (result.list instanceof Array) {
      result.list.push(...response.list.filter(el => result.list.indexOf(el) === -1))
    } else {
      for (const i in response.list) {
        if ((result.list[i] instanceof Array) && (response.list[i] instanceof Array)) {
          result.list[i].push(...response.list[i].filter(el => result.list[i].indexOf(el) === -1))
        } else if (
          (result.list[i] && response.list[i]) &&
          ((result.list[i] instanceof Array) || (response.list[i] instanceof Array))
        ) {
          result.list[i] = Object.assign(result.list[i] || {}, response.list[i])
          if (result.list[i].length !== Object.keys(result.list[i]).length)
            result.list[i] = Object.fromEntries(Object.entries(result.list[i]))
        } else result.list[i] = response.list[i]
      }
    }
  }
  Object.assign(result, response, {list: result.list})
}

/**
 * @param {XMLHttpRequest} xhr
 * @returns {Object}
 * @source https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/getAllResponseHeaders#example
 */
function getHeaders (xhr) {
  // Get the raw header string
  const headers = xhr.getAllResponseHeaders();

  // Convert the header string into an array
  // of individual headers
  const arr = headers ? headers.trim().split(/[\r\n]+/) : [];

  // Create a map of header names to values
  const headerMap = {};
  arr.forEach(function (line) {
    const parts = line.split(': ')
    const header = parts.shift()
    const value = parts.join(': ')
    headerMap[header] = value
  })
  return headerMap
}

/**
 * @param {Object|String} data
 * @returns {Promise.<Object>}
 */
ajax.json = function(data) {
  data = parseArgs(arguments)
  data.dataType = "json"
  return continuous(data)
    .then(json => {
      if (json.code && !isCodeSuccessful(json.code)) {
        throw json
      } else return json
    })
}
/*
ajax.get = function(data) {
  data = parseArgs(arguments)
  data.method = 'GET'
  return send(data).then(xhr => response(xhr, data));
} */

/**
 * @param {number} code
 * @returns {boolean}
 */
function isCodeSuccessful(code) {
  return code >= 200 && code < 300
}

const cancelHandler = function CancelHandler (xhr) {
  this.xhr = xhr
}

ajax.isCancel = function isCancel (error) {
  return error instanceof cancelHandler
}

ajax.options = staticOptions
module.exports = ajax