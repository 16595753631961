<template>
    <div :class="localeCSS" id="root">
        <LoadingSpinner v-if="!ready" class="spinner-center-vertical spinner-big"/>
        <component
            v-else-if="controller.component"
            v-bind="controller.bind"
            :is="controller.component"
            :class="localeCSS"
        />
        <div v-else id="center">
                Hello Peanuts!
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import ajax from "../lib/ajax.js"

import './styles.css'

import LoadingSpinner from '../components/layout/LoadingSpinner'
const { getTranslationFromDictionary } = require('../lib/utility')
const SharedLinkEntry = defineAsyncComponent({loader: () => import('./sharedLink/Entry'), loadingComponent: LoadingSpinner})
const GalleryEntry = defineAsyncComponent({loader: () => import('./listPreset/Entry'), loadingComponent: LoadingSpinner})


export default {
    name: 'App',
    components: { LoadingSpinner },
    props: {
    },
    data() {
        let baseURI
        if (!document.baseURI) {
            baseURI = document.getElementsByTagName("base")[0]
            if (baseURI) baseURI = baseURI.href
        } else baseURI = document.baseURI
        baseURI = (baseURI || '/').substr(document.location.origin.length)
        //this.routing.setBase(baseURI)

        return {
            baseURI,
            dict: {},
            systemLocale: null,
            settings: {},
            state: null,
            purl: false,
            debug: false
        }
    },
    computed: {
        controller () {
            return this.parseLocation(document.location)
        },
        localeCSS() {
            return this.systemLocale
        },
        ready () {
            return this.state === true
        }
    },
    methods: {
        getRoutePath(name, ...args) {
            return '?r=' + name + (args.length ? '/' + JSON.stringify(args) : '')
        },
        setEnvironment() {
            return this.loadDictionary('global')
                .then(() => {
                    this.systemLocale = 'de_DE'//todo
                })
        },
        /**
         * @param {string[]} dictionary
         * @returns {Promise.<boolean>}
         */
        loadDictionary(...dictionary) {
            const loaded = []
            for (let d of dictionary) {
                if (!this.dict[d]) {
                    loaded.push(
                        ajax.json('index.php?dict=' + d).then(({response}) => this.dict[d] = response)
                    )
                }
            }

            return Promise.all(loaded).then(() => true).catch(e => {
                this.throwError('failed to load dictionary.', e)
            })
        },
        /**
         * @param {string} key
         * @param {any[]} params
         */
        l10n(key, ...params) {
            const str = getTranslationFromDictionary(this.dict, key, ...params)
            return str === null ? (this.debug ? 'KEY:' : '') + key : str
        },
        /**
         * @param {string} title
         */
        setTitle(title){
            const meta = document.head.querySelector("meta[name=title][content]")?.content || 'coconutbox';
            document.title = title ? title + ' | ' + meta : meta;
        },
        /**
         * @param {Error|String} error
         * @param {any?} additional
         * @param {Error|XMLHttpRequest|Object?} additional
         */
        throwError(error, additional) {
            //this.hideModal()
            console.error({
                message: (error instanceof Error ? error.message : error) || this.l10n('error_occurred'),
                debug: additional || (error instanceof Error ? error.stack : error)
            })
            throw error
        },
        addTestLabel(label) {
            if (this.debug) return {'data-selenium': label }
            else return null;
        },

        /**
         * @returns {{component: Object, bind: Object}}
         */
        parseLocation() {
            let ctrl = null, bind = null
            const match = /\/([cdu])\/([a-z0-9_-]+)\/([^?#]*)/i.exec(document.location.href)
            const param = new URLSearchParams(window.location.search)
            if (match && 'du'.indexOf(match[1]) !== -1) {
                ctrl = SharedLinkEntry
                bind = {code: match[2], sharedTypeControl: match[1]}
                this.$root.purl = true
            } else if (match && 'c' === match[1]) {
                ctrl = GalleryEntry
                bind = {code: match[2]}
                this.$root.purl = true

            } else if (param.has('key')) {
                ctrl = SharedLinkEntry
                bind = {code: param.get('key')}
                this.$root.purl = false
            } else {
                ctrl = GalleryEntry
                bind = {code: param.get('c')}
                this.$root.purl = false
            }
            if (this.$root.purl) {
                this.baseURI = this.baseURI.replace(match[0], '/').replace(/^(.*?)([?#].*)$/, '$1')
            } else this.baseURI = this.baseURI.split('/').slice(0, -1).join('/').replace(/^(.*?)\/?$/, '$1/')
            return {component: ctrl, bind}
        },
        /** stub */
        setLoadingPercentage(value, delayed = false) {
            /*if (delayed) {
                setTimeout(() => this.loadingPercentage = value, 100)
            } else this.loadingPercentage = value*/
        },
        /**
         * @param error
         * @param additional
         */
        errorScreen(error, additional) {
            this.ready = (error instanceof Error ? error : new Error(error)) || new Error(this.l10n('unknown_error'))
            console.error(error, additional)
        },
    },
    provide() {
        return {
            getRoutePath: this.getRoutePath
        }
    },
    created() {

        this.setEnvironment().then((done) => {
            //if (done) this.currentRoute = this.findRouteByPath(document.location.pathname)
            window.addEventListener('popstate', () => {
                //this.currentRoute = this.findRouteByPath(document.location.pathname)
            })
        }).then(() => this.state = true)
    }
}
</script>

